import {Helmet} from 'react-helmet-async';
// @mui
import {Grid, Container, Typography, Stack, Button, Box} from '@mui/material';
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
// routes
import {Link as RouterLink} from "react-router-dom";

// components
import UseCaseCard from "../components/use-case-card";
import TeamCard from "../components/team-card";

// ----------------------------------------------------------------------
const DesktopConsultingSection = () => {
    return (
        <Grid container alignItems="flex-start" justifyContent="flex-start" spacing={3}>
            <Grid item xs={12} md={4}>
                <Typography variant={"h2"} paragraph>
                    Consulting
                </Typography>
                <Typography variant={'overline'} color={'primary'} fontSize={16} paragraph>
                    Having trouble with your data? <br/>
                </Typography>
                <Typography paragraph>
                    OmniumAI helps biotech companies leverage their data to make better decisions.
                    Our team includes Food Scientists, Machine Learning Engineers, Bioinformaticians, and
                    Cheminformaticians.
                </Typography>
                <Button color={'primary'}
                        sx={{mt: 3}}
                        variant="contained"
                        component={RouterLink}
                        size={"large"}
                        to={"/services"}>
                    Know more
                </Button>
            </Grid>
            <Grid item xs={12} md={7}>
                <Stack direction={'row'} justifyContent={'center'} alignItems={'center'}>
                    <img src="/assets/consulting.jpg" alt="Services" height={"375px"} style={{borderRadius: "2%"}}/>
                </Stack>
            </Grid>
        </Grid>
    )
}

const MobileConsultingSection = () => {
    return (
        <Grid container alignItems="center" justifyContent="center" spacing={3}>
            <Grid item xs={12}>
                <Typography variant={"h2"} align={'center'} paragraph>
                    Consulting
                </Typography>
                <Typography variant={'overline'} align={'center'} color={'primary'} fontSize={16} paragraph>
                    Having trouble with your data? <br/>
                </Typography>
                <Stack direction={'row'} justifyContent={'center'} alignItems={'center'}>
                    <img src="/assets/consulting.jpg" alt="Services" height={"300px"} style={{borderRadius: "2%"}}/>
                </Stack>
                <Typography sx={{pt: 2}} align={'center'} paragraph>
                    OmniumAI helps biotech companies leverage their data to make better decisions.
                    Our team includes Food Scientists, Machine Learning Engineers, Bioinformaticians, and
                    Cheminformaticians.
                </Typography>
                <Stack direction={'row'} justifyContent={'center'} alignItems={'center'}>
                    <Button color={'primary'}
                            sx={{mt: 3}}
                            variant="contained"
                            component={RouterLink}
                            size={"large"}
                            to={"/services"}>
                        Know more
                    </Button>
                </Stack>
            </Grid>
        </Grid>
    )
}

// ----------------------------------------------------------------------
export default function HomePage() {
    const theme = useTheme();
    const matchesMd = useMediaQuery(theme.breakpoints.up('md'));

    const backgroundImageStyle = {
        backgroundColor: "#212B36",
        backgroundImage: `url('/assets/home-page-bg.svg')`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPositionX: 'left',
        backgroundPositionY: 'center',
    }

    return (
        <>
            <Helmet>
                <title> OmniumAI </title>
            </Helmet>
            <Grid
                container
                sx={{
                    px: {xs: 1, sm: 2, md: 3}, py: {xs: 1, sm: 2},
                    minHeight: '70vh',
                    minWidth: '100vw',
                    ...backgroundImageStyle
                }}
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                spacing={3}>
                <Grid item xs={7} sm={5} md={4} lg={3}>
                    <Typography variant="h3" sx={{pt: 5}} color={'#fff'}>
                        Decoding Life,
                    </Typography>
                    <Typography variant="h3" color={'#fff'}>
                        Leveraging Nature
                    </Typography>
                    <Typography
                        sx={{my: 5}}
                        color={'#e5e5e5'}
                        variant="h4"
                        paragraph
                    >
                        Artificial Intelligence and Data Science Solutions for the
                        <Typography color={"success.main"}
                                    component={'span'}
                                    variant={"h4"}>{' '} Life Sciences
                        </Typography>
                    </Typography>
                    <Box sx={{pb: {xs: 2, sm: 0}}}>
                        <Button color={'primary'}
                                variant="contained"
                                component={RouterLink}
                                size={"large"}
                                to={"mailto:info@omniumai.com"}>
                            Get in touch
                        </Button>
                    </Box>
                </Grid>
                <Grid item xs={5} sm={7} md={8} lg={9}>
                </Grid>
            </Grid>
            <Container maxWidth="xl"
                       sx={{px: {xs: 1, md: 3}, pt: 10, pb: 3}}>
                <div>
                    <Typography variant={"h3"} sx={{textAlign: "center"}} paragraph>
                        Trusted by the world's leading companies
                    </Typography>
                    <Stack direction={'row'}
                           justifyContent={'center'}
                           alignItems={'center'}
                           spacing={{xs: 2, md: 10, lg: 20}}
                           useFlexGap
                           flexWrap="wrap">
                        <img src="/assets/chr-hansen.png" alt="Chr. Hansen" height={"200px"}/>
                        <img src="/assets/jm.png" alt="Jerónimo Martins" height={"200px"}/>
                    </Stack>
                </div>
                <Stack spacing={{xs: 10, md: 15, lg: 20}} sx={{pt: 10}}>
                    <div>
                        <Typography variant={"h2"} paragraph>
                            Our Products
                        </Typography>
                        <Grid container alignItems="center" justifyContent="flex-start" spacing={3}>
                            <Grid item xs={12} md={6} lg={4}>
                                <UseCaseCard
                                    useCase={{
                                        cover: `/assets/yogurt.jpg`,
                                        title: "CibusAI",
                                        subTitle: "CibusAI empowers food companies with AI-driven reformulation.",
                                        link: "https://cibus.omniumai.com"
                                    }}
                                    index={0}/>
                            </Grid>
                            <Grid item xs={12} md={6} lg={4}>
                                <UseCaseCard
                                    useCase={{
                                        cover: `/assets/ai_img.png`,
                                        title: "OmniA",
                                        subTitle: "Automated Machine Learning for Bioinformatics and Cheminformatics.",
                                        link: "/products"
                                    }}
                                    index={0}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} lg={4}>
                                <UseCaseCard
                                    useCase={{
                                        cover: `/assets/omics_img.png`,
                                        title: "OmniNGS",
                                        subTitle: "Food traceability using Next Generation Sequencing.",
                                        link: "/products"
                                    }}
                                    index={0}/>
                            </Grid>
                        </Grid>
                        <Box sx={{textAlign: 'center', pt: {xs: 10, sm: 5}}}>
                            <Typography variant={'overline'} color={'primary'} fontSize={16} paragraph>
                                Can't find what you're looking for? <br/>
                            </Typography>
                            <Typography paragraph>
                                OmniumAI can help you build a custom solution for your business.
                            </Typography>
                            <Button color={'primary'}
                                    sx={{mt: 3}}
                                    variant="contained"
                                    component={RouterLink}
                                    size={"large"}
                                    to={"mailto:info@omniumai.com"}>
                                Talk to us
                            </Button>
                        </Box>
                    </div>
                    <div>
                        {matchesMd ? <DesktopConsultingSection/> : <MobileConsultingSection/>}
                    </div>
                    <div>
                        <Grid container alignItems="center" justifyContent="center" spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <Typography variant={"h2"} align={"center"} paragraph>
                                    Who are we?
                                </Typography>
                                <Typography variant={'overline'} align={"center"} fontSize={16} paragraph>
                                    Since 2021, Igniting Innovation from Braga to the World!
                                </Typography>
                                <Typography align={"center"} paragraph>
                                    OmniumAI is a spin-off of the University of Minho
                                    created from the Centre of Biological Engineering.
                                </Typography>
                                <Typography align={"center"} paragraph>
                                    OmniumAI lives through a young and enthusiastic team of scientists and engineers
                                    with broad expertise in artificial intelligence and data science applied to biotech.
                                </Typography>
                                <Stack direction={'row'} justifyContent={'center'} alignItems={'center'}>
                                    <img src="/assets/spinoff_uminho_color.png"
                                         alt="spinoff"
                                         height={"250px"}/>
                                </Stack>
                            </Grid>
                        </Grid>
                    </div>
                    <div>
                        <Typography variant={"h2"} align={"center"}>
                            Meet The Team
                        </Typography>
                        <Grid container
                              sx={{pt: 3}}
                              justifyContent="flex-start"
                              alignItems="center"
                              spacing={3}>
                            <TeamCard member={{
                                image: "/assets/odias.jpg", title: 'CEO', name: 'Oscar Dias',
                                linkedin: "https://www.linkedin.com/in/oscar-dias-1284763b/",
                                description: 'Oscar Dias is an Assistant Researcher at the University of Minho holding a PhD in Chemical and Biological Engineering. Oscar has an extensive experience in machine learning applied to Bioinformatics, Systems Biology, and Biotechnology.'
                            }}/>
                            <TeamCard member={{
                                image: "/assets/mrocha.jpg", title: 'CSO', name: 'Miguel Rocha',
                                linkedin: "https://www.linkedin.com/in/miguel-rocha-8835619",
                                description: 'Miguel Rocha is an Associate Professor at the University of Minho holding a PhD in Computer Science. Bioinformatics and Systems Biology are his main areas of expertise, focusing on machine learning methods for modelling and optimizing biological systems.'
                            }}/>
                            <TeamCard member={{
                                image: "/assets/msampaio.jpg", title: 'ML Engineer', name: 'Marta Sampaio',
                                linkedin: "https://www.linkedin.com/in/marta-sampaio-7895b3127/",
                                description: 'Marta Sampaio is holds a PhD from University of Minho. Marta has been focussed on machine learning applied to biology.'
                            }}/>
                            <TeamCard member={{
                                image: "/assets/jcorreia.png", title: 'ML Engineer', name: 'João Correia',
                                linkedin: "https://www.linkedin.com/in/joaocorreia95",
								description: 'João Correia is a PhD student at the University of Minho holding an MSc in Bioinformatics. Marta has been focussed on machine learning applied to chemoinformatics.'
                            }}/>
                            <TeamCard member={{
                                image: "/assets/dmacedo.jpeg", title: 'Software Engineer', name: 'Diogo Macedo',
                                linkedin: "https://www.linkedin.com/in/diogo-macedo",
                                description: 'Diogo Macedo holds a Msc in Bioinformatics from the University of Minho.'
                            }}/>
                            <TeamCard member={{
                                image: "/assets/rpereira.jpg",
                                title: 'CFSO',
                                name: 'Ricardo Pereira',
                                linkedin: "https://www.linkedin.com/in/ricardo-pereira-0b0a62a/",
                                description: 'Ricardo Pereira has a PhD in Chemical and Biological Engineering from the University of Minho in Portugal, where he was also a faculty member at the Centre of Biological Engineering. Ricardo has an extensive experience in food biotechnology, food innovation, food processing, and food safety.'
                            }}/>
                        </Grid>
                    </div>
                    <div>
                        <Stack
                            justifyContent="center"
                            alignItems="center"
                            spacing={3}>
                            <>
                                <Typography variant={"h2"}>
                                    Innovation that materializes
                                </Typography>
                                <Typography>
                                    Get in touch and work with us to accelerate your R&D.
                                </Typography>
                            </>
                            <Button color={'primary'}
                                    variant="contained"
                                    component={RouterLink}
                                    size={"large"}
                                    to={"mailto:info@omniumai.com"}>
                                Talk to us
                            </Button>
                        </Stack>
                    </div>
                </Stack>
            </Container>
        </>
    )
}